<template>
  <div>
    <!-- Breadcrumb start -->
    <section class="bradcrumb_sec">
      <div class="container-fluid custome_container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="bread_crumb_box mb_22">
              <h2 class="heading_2 font_bold mb_23">
                Work better
                <span class="text_primary">Productivity & Wellness</span>
              </h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link
                      class="navbar-brand m-0 p-0"
                      :to="{ name: 'user_home' }"
                    >
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Work Better
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Breadcrumb end -->
    <section :class="!company_code ? 'work_from_office how_else_ergo work_better mb-0' : 'work_from_office how_else_ergo work_better'" >
      <!-- <img src="../assets/images/work-from-office/first-section.png" alt="img" class="first_section"> -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xxl-12">
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2 border-0">
                  <router-link :to="{ name: 'beauty_sleep' }">
                    <img
                      src="../assets/images/work_better/wb_iye.jpg"
                      alt="img"
                      class="mb_66"
                    />
                    <div class="card2_text_box">
                      <h5
                        class="font_bold text-center font_size_26 mb_20 text_black"
                      >
                        Increase Your Energy
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Increase your productivity by <br />increasing your
                        energy. Learn how.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2 border-0">
                  <router-link :to="{ name: 'how_it_works' }">
                    <img
                      src="../assets/images/work_better/wb_tpt.jpg"
                      alt="img"
                      class="mb_66"
                    />
                    <div class="card2_text_box">
                      <h5
                        class="font_bold text-center font_size_26 mb_20 text_black"
                      >
                        The Pomodoro Technique
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Discover the joys of the Pomodoro<br />
                        Technique to boost your output.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2 border-0">
                  <router-link :to="{ name: 'sensory_overload_spot' }">
                    <img
                      src="../assets/images/work_better/wb_so.jpg"
                      alt="img"
                      class="mb_66"
                    />
                    <div class="card2_text_box">
                      <h5
                        class="font_bold text-center font_size_26 mb_20 text_black"
                      >
                        Sensory Overload
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Learn how to unplug and take <br />
                        a break from it all.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2 border-0">
                  <router-link :to="{ name: 'productivity_structure' }">
                    <img
                      src="../assets/images/work_better/wb_ph.jpg"
                      alt="img"
                      class="mb_66"
                    />
                    <div class="card2_text_box">
                      <h5
                        class="font_bold text-center font_size_26 mb_20 text_black"
                      >
                        Productivity Hacks
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Try these unique hacks to help<br />
                        you crush your work day.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2 border-0">
                  <router-link :to="{ name: 'time_management_energy' }">
                    <img
                      src="../assets/images/work_better/wb_tm.jpg"
                      alt="img"
                      class="mb_66"
                    />
                    <div class="card2_text_box">
                      <h5
                        class="font_bold text-center font_size_26 mb_20 text_black"
                      >
                        Time Management
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Manage your time and enjoy a more <br />balanced life.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2 border-0">
                  <router-link :to="{ name: 'enhanced_focus_concentration' }">
                    <img
                      src="../assets/images/work_better/wb_ef.jpg"
                      alt="img"
                      class="mb_66"
                    />
                    <div class="card2_text_box">
                      <h5
                        class="font_bold text-center font_size_26 mb_20 text_black"
                      >
                        Enhanced Focus
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Increase your focus with <br />
                        these simple techniques.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="want_more_stretches mb_100 my_100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="bg_primary">
              <div class="row">
                <div class="col-md-12 col-xl-8 align-self-center">
                  <div class="left-section pt_42 pb_54 pl_50 mt_6">
                    <h6 class="sub_heading_1 mb_0 text-white">
                      Don’t see what you’re looking for?
                    </h6>
                    <p class="text-white font_size_24 fst-italic mb_0">
                      Check out our full video library to discover more ergo
                      tips.
                    </p>
                  </div>
                </div>
                <div class="col-md-12 col-xl-4 align-self-center text-xl-end">
                  <div class="right-section pt_26 pb_26 pl_60 pr_40">
                    <div class="mt_14">
                      <router-link
                        :to="{ name: 'ergo_video_library' }"
                        class="btn btn_secondary text-capitalize font_size_16"
                        >Video Library</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
        
    }
  },
  name: "Work_better",
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
  }  
};
</script>
